body {
  width: 100vw;
  overflow-x: hidden;
  max-width: 100%;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width: 100vw;
  overflow-x: hidden;
  text-align: center;
  /* background-color: #f7f7f7; */
  /* background-color: #e7e7e7; */
  background-color: #111;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#menu-appbar div {
  background-color: transparent !important;
  box-shadow: none !important;
}

.header .logo img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.header button {
  /* font-family: "acumin-pro" !important;
  font-weight: 600; */
}

.header a {
  text-decoration: none !important;
  color: #fff;
}

.footer a {
  text-decoration: none !important;
  color: #626262;
}

.pre-first-section {
  height: 100vh;
  overflow: hidden;
}

.pre-first-section a {
  text-decoration: none;
  color: #fff;
}

.pre-first-section h1 {
  font-family: "acumin-pro" !important;
  font-weight: 600;
  color: #fff;
}

.pre-first-section h3 {
  /* font-family: "acumin-pro" !important; */
  font-weight: 300;
  color: #fff;
}

.pre-first-section .slick-slide:focus,
.pre-first-section .slick-slide div {
  outline: none;
}

.pre-first-section .slick-active .title,
.pre-first-section .slick-active .detail {
  animation: fadein 3s;
  transform: translate(0, 10vh);
  transition: all 2s;
}

.pre-first-section .slick-dots {
  bottom: 25px !important;
}

.pre-first-section .slick-dots li button:before {
  color: #b0b0b0 !important;
}

.pre-first-section .slick-dots li.slick-active button:before {
  color: #fff !important;
}

.product-page .slick-slide:focus,
.product-page .slick-slide div {
  outline: none;
}

.product-page .slick-active .title,
.product-page .slick-active .detail {
  animation: fadein 3s;
  transform: translate(0, 10vh);
  transition: all 2s;
}

.product-page .slick-dots {
  bottom: 25px !important;
}

.second-section {
  width: 100vw;
  display: flex;
  justify-content: center;
  /* overflow: hidden; */
}

.second-section a {
  color: #626262;
}

.second-section .img,
.fourth-section .img {
  animation: rotate_image 8s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes rotate_image {
  100% {
    transform: rotate(360deg);
  }
}

.fourth-section {
  display: flex;
  justify-content: center;
}

.fitting button {
  color: #c7c7c7;
}

.fitting button:first-child {
  margin-top: 32px;
}

.fitting .Mui-selected {
  color: #fff !important;
}

.fitting .MuiTabs-indicator {
  background-color: #aa2727 !important;
}

.terms {
  display: flex;
  justify-content: center;
}

.terms ul {
  padding-left: 0;
}

.terms li {
  list-style: none;
  margin-bottom: 30px;
}

.terms p {
  margin: 0;
}

.terms strong {
  display: block;
  padding-bottom: 12px;
}

.product-page .slick-dots li.slick-active button:before {
  color: #060606 !important;
}

.form h1 {
  font-weight: 500;
  color: white;
  text-align: center;
  letter-spacing: 8px;
  margin-bottom: 50px;
}

.form div {
  text-align: left;
}

.form {
  /* background: #0e101c; */
  /* max-width: 400px; */
  /* margin: 0 auto; */
}

form h5 {
  text-align: left;
  color: #bf1650;
}

.form a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 8px;
}

form .border::before,
form .border::after {
  border-top: thin solid #ffffff4a;
}

form .border svg {
  vertical-align: top;
  margin-left: 5px;
}

form h5::before {
  display: inline;
  content: "⚠ ";
}

.form h2 {
  margin-bottom: 5px;
  color: #fff;
}

.form h2 a {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.form .white {
  padding: 0 15px;
  color: #fff;
}

.form .red {
  padding: 0 16px;
  color: #bf1650;
}

.form input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 14px;
}

.form button {
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #aa2727;
  background-color: #aa2727;
  padding: 15px;
  margin-bottom: 55px;
  font-size: 14px;
  text-align: center;
}

form .emailCheck {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(183, 183, 183);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 12px;
  font-size: 14px;
}

form .h5 {
  margin-block-end: 0;
}

.fourth-section .list-img {
  object-fit: cover;
  width: 100%;
  max-height: 200px;
}

/* .fitting svg {
  display: none;
} */
